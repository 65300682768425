import React, { useEffect } from "react";
import { graphql } from "gatsby";

import { QueryFragments } from "../GraphQl/queryFragments"; // eslint-disable-line

// Components
import { PageMeta } from "../components/pageMeta";
import { ProjectBanner, RelatedProjects } from "../components/blockZone/blocks";
import BlockZone from "~blockZone";
import Layout from "../components/layout";

const ProjectTemplate = ({ data: { page, relatedProjects } }) => {
  const {
    title,
    category,
    meta,
    featuredImage,
    location,
    shortDesc,
    pageBlocks,
    longDesc,
    isVideo,
    video,
    bannerImage,
  } = page || {};

  const catClass = (category && category.slug && category.slug.current) || null;

  useEffect(() => {
    document.documentElement.classList.add(`theme-${catClass}`);
    return () => {
      document.documentElement.classList.remove(`theme-${catClass}`);
    };
  }, [catClass]);

  return (
    <Layout splitNav={true}>
      <ProjectBanner
        category={category}
        title={title}
        featuredImage={featuredImage}
        location={location}
        shortDesc={shortDesc}
        longDesc={longDesc}
        isVideo={isVideo}
        video={video}
        bannerImage={bannerImage}
      />
      <PageMeta {...meta} />
      {pageBlocks && <BlockZone {...pageBlocks} />}
      <RelatedProjects projects={relatedProjects} />
    </Layout>
  );
};

export default ProjectTemplate;

export const pageQuery = graphql`
  query projectQuery($slug: String!, $cat: String!) {
    page: sanityProject(slug: { current: { eq: $slug } }) {
      featuredImage {
        ...ImageWithPreview
      }
      bannerImage {
        ...ImageWithPreview
      }
      isVideo
      video
      title
      location
      shortDesc
      longDesc: _rawLongDesc(resolveReferences: { maxDepth: 10 })
      category {
        title
        slug {
          current
        }
      }
      meta {
        ...MetaCardFields
      }
      pageBlocks {
        ...BlockZone
      }
    }
    relatedProjects: allSanityProject(
      sort: { fields: order }
      filter: {
        slug: { current: { ne: $slug } }
        category: { slug: { current: { eq: $cat } } }
      }
    ) {
      edges {
        node {
          featuredImage {
            ...Image
          }

          title
          location
          order
          shortDesc
          category {
            slug {
              current
            }
          }
          slug {
            current
          }
        }
      }
    }
  }
`;
